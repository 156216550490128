import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { MarkdownRemarkEdge } from 'graphql-types';
import { StringParam, useQueryParam } from 'use-query-params';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Zoom from '@mui/material/Zoom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { IconButton } from '@mui/material';

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

function ScrollTop(props: Props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}

type BasePagePropsType = {
  className?: string;
  data: any;
  props: Props;
};

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          frontmatter {
            title
            category
            categoryj
            slug
            date(formatString: "YYYY年")
            image {
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
          }
          id
          excerpt(truncate: true, pruneLength: 80)
          html
        }
      }
    }
  }
`;

const BasePage: React.FC<BasePagePropsType> = ({ className, data, props }) => {
  const [category] = useQueryParam('category', StringParam);
  const edges: MarkdownRemarkEdge[] = data.allMarkdownRemark.edges;

  const obj = edges.reduce((accu: { [key: string]: boolean }, edge) => {
    const id = edge.node.id;
    if (id in accu) return accu;
    accu[id] = false;
    return accu;
  }, {});
  const [state, setState] = useState<{ [key: string]: boolean }>(obj);

  const onOpenCommentHandler = useCallback((id: string) => {
    setState((prevState) => {
      return { ...prevState, [id]: true };
    });
  }, []);

  return (
    <div className={className}>
      <CssBaseline />
      <AppBar sx={{ backgroundColor: '#ffffff' }}>
        <Toolbar>
          <Link to={category === 'home' ? '/' : `/${category}/`}>
            <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
              <ArrowBackIosNewIcon sx={{ color: '#5a5a5a' }} />
            </IconButton>
          </Link>
          <Typography
            variant="h6"
            component="div"
            sx={{
              marginBottom: '-4px',
              color: '#5a5a5a',
              fontSize: '18px',
              fontWeight: 600,
              textDecoration: 'none',
            }}
          >
            戻る
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar id="back-to-top-anchor" />
      <Container>
        <Box>
          <div className="container">
            <h1>お客様の声</h1>
            <ul>
              {edges.map((edge) => {
                const { node } = edge;
                const { frontmatter, id, excerpt, html } = node;
                return (
                  <li key={id}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        verticalAlign: 'bottom',
                        lineHeight: 1,
                        marginBottom: '20px',
                      }}
                    >
                      <h2>{frontmatter?.title ?? ''}</h2>
                      <div style={{ flex: 1 }} />
                      <span className="category">
                        {/* {frontmatter?.date ?? ''} */}
                        {frontmatter?.categoryj ?? ''}
                      </span>
                    </div>
                    <div style={{ display: 'flex', marginBottom: '10px' }}>
                      <GatsbyImage
                        image={getImage(frontmatter?.image?.childImageSharp?.gatsbyImageData)!}
                        alt={''}
                      />
                    </div>

                    {excerpt && (
                      <>
                        {!state[id] && (
                          <>
                            <span className="article-list-item-excerpt">{excerpt}</span>
                            <p className="more" onClick={() => onOpenCommentHandler(id)}>
                              さらに表示
                            </p>
                          </>
                        )}
                        {!!state[id] && (
                          <div
                            className="article-body"
                            dangerouslySetInnerHTML={{ __html: html ?? '' }}
                          />
                        )}
                      </>
                    )}
                    {/* <Link to={frontmatter?.slug ?? ''}>詳細</Link> */}
                  </li>
                );
              })}
            </ul>
          </div>
        </Box>
      </Container>
      <ScrollTop {...props}>
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </div>
  );
};

const Page = styled(BasePage)`
  ${() => {
    return css`
      & {
        color: #797979;
        .container {
          margin: 40px auto;
        }
        h1 {
          font-size: 26px;
          font-weight: 600;
          text-align: left;
          margin: 40px 0;
          color: #5a5a5a;
        }
        h2 {
          font-size: 16px;
          font-weight: 600;
          text-align: left;
          color: #fda359;
          margin: 0;
        }
        span.category {
          font-size: 14px;
          font-weight: 600;
          text-align: right;
        }
        p.more {
          font-size: 14px;
          color: blue;
          text-align: right;
        }
        .spacer30 {
          margin-bottom: 30px;
        }

        ul {
          list-style: none;
          margin-left: 0;
          padding-left: 0;
        }
        li {
          border-bottom: solid 2px #eeeeee;
          margin-bottom: 20px;
        }
      }
    `;
  }}
`;
export default Page;
